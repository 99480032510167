html{
  height: 100%
}
body{
  font-size: 18px;
  font-family: roboto;
  height: 100%;
  padding: 0;
  margin: 0;
  font-weight: lighter;
}
#root{
  width: 100%;
  height: 100%;
}
